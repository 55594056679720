// Here you can add other styles
.text-success {
    color: $primary-color;
}

.btn {
    border: none;
}

.btn-success {
    background-color: $primary-color;
}

.btn-danger {
    background-color: $danger-color;
}

.btn-primary,
.bg-primary {
    background-color: $info-color !important;
}

.btn-dark-grey,
.bg-dark-grey {
    background-color: $dark-grey-color !important;
}

.c-sidebar {
    background: #4e5561;

    &-nav-item:hover &-nav-link {

        background-color: #464c57 !important;
    }
}

// Loading overlay
.overlay {
    width: 100%;
    height: 100%;
    background: transparent;

    &__inner {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__content {
        padding-top: 50px;
    
        &-spinner {
            width: 75px;
            height: 75px;
            display: inline-block;
            border-width: 2px;
            border-color: #ccc;
            border-top-color: #4e5561;
            animation: spin 1s infinite linear;
            border-radius: 100%;
            border-style: solid;
        }
    }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.uploaded-files {
    display: flex;
    flex-direction: column;

    & > div {
        border: 1px solid #ccc;
        padding: 10px;
        margin-bottom: 10px;

        & .remove-file {
            cursor: pointer;
        }
    }
}